@font-face {
  font-family: ibm-plex-arabic;
  src:
    url('../fonts/IBMPlexArabic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: ibm-plex-bold-arabic;
  src:
    url('../fonts/IBMPlexArabic-Bold.ttf') format('truetype');
}


@font-face {
  font-family: 'slick';
  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'),
    url('../fonts/slick.woff') format('woff'),
    url('../fonts/slick.ttf') format('truetype'),
    url('../fonts/slick.svg#slick') format('svg');
}

