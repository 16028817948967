@import 'mapbox-gl';
@import '~bootstrap/scss/bootstrap';
@import 'fonts';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  overflow: hidden;
  font-family: ibm-plex-arabic;
}

html {
  &[dir='rtl'] {
    body {
      text-align: right;
    }
  }
}


.minimum-full-height {
  min-height: 100vh;
}

.vh-100 {
  height: 100vh;
}

.map-component {
  overflow: hidden;

  .controllers {
    position: relative;
    z-index: 10;

    background-color: black;
    width: 243px;
    height: 100%;
    color: white;
    transition: 0.3s transform;
    transform: translateX(100%);

    .heading {
      $spacing: 1rem;
      padding-bottom: $spacing;
      border-bottom: 1px solid rgba(white, 0.6);
    }

    .filters {
      .filter {
        .filter-heading {
          cursor: pointer;
          color: rgba(white, 0.6);

          .checkbox {
            $d1: 20px;
            width: $d1;
            height: $d1;
            border-radius: $d1/8;
            border: 2px solid rgba(white, 0.4);
            position: relative;

            &::after {
              content: '';
              $d2: 10px;
              width: $d2;
              height: $d2;
              border-radius: $d2/2;
              background-color: rgba(white, 0.4);
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
              display: none;
            }
          }
        }

        &:hover {
          .filter-heading {
            color: rgba(white, 1);
          }
        }

        &.active {
          .filter-heading {
            color: rgba(white, 1);
          }

          .checkbox {
            border-color: white;

            &::after {
              display: block;
              background-color: rgba(white, 1);
            }
          }
        }

        .slider-component {
          color: white;
          .MuiSlider-thumb {
            &.MuiSlider-active {
              box-shadow: 0px 0px 0px 14px rgba(255, 255, 255, 0.16);
            }
          }
        }
      }
    }

    .actions {
      $color: rgba(white, 0.4);
      bottom: 0;
      left: 0;
      width: 100%;
      .action {
        overflow: hidden;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }

        button {
          padding: 0;
          color: $color;
          cursor: pointer;
          $diameter: 50px;
          width: $diameter;
          height: $diameter;
          border-radius: $diameter/2;
          border: 2px solid $color;
          display: flex;
          justify-content: center;
          align-items: center;
          .angle {
            width: ($diameter * 0.6 / 2);
            height: ($diameter * 0.6 / 2);
            border: 1px solid;
            border-color: $color $color transparent transparent;
            transform: translateX(-25%) rotate(45deg);
          }
          .split {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $diameter;
            height: $diameter;
            .column {
              width: 1px;
              height: 100%;

              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0);
              background-color: $color;
            }
            .dot {
              width: 8px;
              height: 33.3%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: $color;
            }
          }

          &[disabled] {
            cursor: not-allowed;
          }

          &:not([disabled]) {
            &:hover {
              border-color: white;
              color: white;
              .angle {
                border-color: white white transparent transparent;
              }
              .split {
                .column {
                  background-color: white;
                }
                .dot {
                  background-color: white;
                }
              }
            }
          }
        }
      }
    }

    .toggle-back {
      $color: rgba(black, 0.8);
      display: block;
      position: absolute;
      bottom: 0;
      right: 100%;
      z-index: 0;
      transform: scale(-1);
      margin-bottom: 1em;
      margin-right: 1em;

      .btn {
        $radius: 25px;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $radius * 2;
        height: $radius * 2;
        border-radius: $radius;
        border-color: white!important;
        border-width: 2px;
        text-decoration: none !important;
        background-color: black;

        &:hover {
          opacity: 1;
        }

        .angle {
          border-color: rgba(255, 255, 255, 0.8) rgba(255, 255, 255, 0.8) transparent transparent!important;
        }
      }
    }

    .controllers-container {
      height: 100%;
      overflow: auto;
    }
  }

  &.controllers-is-active {
    .controllers {
      transform: translateX(0%);
    }

    .toggle-back {
      display: none;
    }
  }

  .map-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 25vw;
    height: 100vh;
    min-width: 300px;
    transition: 0.3s transform;
    transform: translateX(-100%);
    padding-top: 69.25px;
    z-index: 11;

    .main {
      background-color: black;
      color: white;
      position: relative;
      z-index: 1;
    }

    .overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      width: 125vw;
      height: 100vh;
      z-index: 0;
    }

    .close-button {
      display: none;
      position: absolute;
      top: auto;
      left: 100%;
      background-color: black;
      color: white;
      z-index: 2;

      &:hover {
        color: rgba(white, 0.6);
      }
    }

    &.active {
      transform: translateX(0%);

      .close-button {
        display: block;
      }

      .overlay {
        display: block;
      }
    }
  }

  .map-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    direction: ltr;
    z-index: 0;

    // background-color:#269;
    background-color: #f5f5f5;
    background-image: linear-gradient(white 2px, transparent 2px),
      linear-gradient(90deg, white 2px, transparent 2px),
      linear-gradient(rgba(255, 255, 255, 0.3) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;

    & > * {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &.comparsion {
      z-index: 1;
      width: 100%;
      height: 100%;
      background: none;

      .first-map,
      .second-map {
        height: 100%;

        & > * {
          height: 100%;
        }
      }

      .first-map {
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      .second-map {
        width: 50%;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        .second-map-container {
          width: 100vw;
          height: 100vh;

          & > * {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
          }
        }

        .controller {
          cursor: col-resize;
          position: absolute;
          right: 0;
          height: 100%;
          width: 4px;
          background-color: black;
          z-index: 1;

          .controller-pin {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: black;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            color: white;
          }
        }
      }
    }
  }

  .additional-tools {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 10;

    .btn {
      $radius: 25px;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      width: $radius * 2;
      height: $radius * 2;
      border-radius: $radius;
      border-color: white;
      border-width: 2px;
      text-decoration: none !important;
      background-color: black;

      &[disabled] {
        background-color: transparent;
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: all;

        &:hover {
          opacity: 0.4;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

	.share-buttons {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
    z-index: 1;

		.btn {
			color: white;
			text-decoration: none;
		}
  }
}

.filter.map-item {
  z-index: 20;

  .icon {
    color: white;
    margin-top: 6px;
  }

  .filter-heading {
    cursor: pointer;
    color: rgba(white, 0.6);

    .checkbox {
      $d1: 20px;
      width: $d1;
      height: $d1;
      border-radius: $d1/8;
      border: 2px solid rgba(white, 0.4);
      position: relative;

      &::after {
        content: '';
        $d2: 10px;
        width: $d2;
        height: $d2;
        border-radius: $d2/2;
        background-color: rgba(white, 0.4);
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        display: none;
      }
    }
  }

  &:hover {
    .filter-heading {
      color: rgba(white, 1);
    }
  }

  &.active {
    .filter-heading {
      color: rgba(white, 1);
    }

    .checkbox {
      border-color: white;

      &::after {
        display: block;
        background-color: rgba(white, 1);
      }
    }
  }

  .slider-component {
    color: white;
    .MuiSlider-thumb {
      &.MuiSlider-active {
        box-shadow: 0px 0px 0px 14px rgba(255, 255, 255, 0.16);
      }
    }
  }
}

.mapboxgl-control-container {
  display: none;
}

.watermark {
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  z-index: 1;

  img {
    width: 100%;
    max-width: 120px;
  }
}


.custom-marker {
  $backgroundColor: rgba(black, 0.7);
  font-size: 16px;

  .custom-marker-body {
    font-family: ibm-plex-arabic, sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: $backgroundColor;
    color: white;
    position: absolute;
    white-space: nowrap;

    left: 108px;
    top: -70px;
  }

  .custom-marker-decoration {
    $color: black;
    $width: 2px;
    $height: 80px;

    position: absolute;
    bottom: 100%;
    right: 100%;
    display: block;
    width: $width;
    height: $height;
    background-color: $backgroundColor;
    transform: translate(50%, 0) rotate(45deg);
    transform-origin: center bottom;

    &::after {
      content: '';
      display: block;
      width: $width;
      height: $height * 0.667;
      background-color: $backgroundColor;
      position: absolute;
      bottom: 100%;
      // left: 50%;
      // margin-bottom: -1px;
      transform: rotate(45deg) translateX(-50%);
      transform-origin: center bottom;
    }
  }

  .custom-marker-point {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: black;
    border: 2.5px solid white;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 7px;
    transform: translate(-50%, -50%);
  }

  &.left {
    .custom-marker-body {
      left: auto;
      right: 108px;
    }

    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(-45deg);
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &.clock-01 {
    .custom-marker-body {
      left: 38px;
      right: auto;
      top: -150px;
      transform: translateX(-50%);
    }
    .custom-marker-decoration {
      transform: rotate(30deg);
      &::after {
        transform: rotate(-30deg);
      }
    }
  }

  &.clock-02 {
    .custom-marker-body {
      left: 122px;
      right: auto;
      top: -53px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(60deg);
      &::after {
        transform: rotate(30deg);
      }
    }
  }

  &.clock-03 {
    .custom-marker-body {
      left: 133px;
      right: auto;
      top: -15px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(90deg);
      &::after {
        transform: rotate(0deg);
      }
    }
  }

  &.clock-04 {
    .custom-marker-body {
      left: 122px;
      right: auto;
      top: 26px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(120deg);
      &::after {
        transform: rotate(-30deg);
      }
    }
  }

  &.clock-05 {
    .custom-marker-body {
      left: 40px;
      right: auto;
      top: 122px;
      transform: translateX(-50%);
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(150deg);
      &::after {
        transform: rotate(30deg);
      }
    }
  }

  &.clock-06 {
    .custom-marker-body {
      left: 0px;
      right: auto;
      top: 133px;
      transform: translateX(-50%);
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(180deg);
      &::after {
        transform: rotate(0deg);
      }
    }
  }

  &.clock-07 {
    .custom-marker-body {
      right: 35px;
      left: auto;
      top: 122px;
      transform: translateX(50%);
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(210deg);
      &::after {
        transform: rotate(-30deg);
      }
    }
  }

  &.clock-08 {
    .custom-marker-body {
      right: 122px;
      left: auto;
      top: 24px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(240deg);
      &::after {
        transform: rotate(30deg);
      }
    }
  }

  &.clock-09 {
    .custom-marker-body {
      right: 133px;
      left: auto;
      top: -16px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(270deg);
      &::after {
        transform: rotate(0deg);
      }
    }
  }

  &.clock-10 {
    .custom-marker-body {
      right: 122px;
      left: auto;
      top: -55px;
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(300deg);
      &::after {
        transform: rotate(-30deg);
      }
    }
  }

  &.clock-11 {
    .custom-marker-body {
      right: 40px;
      left: auto;
      top: -150px;
      transform: translateX(50%);
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(330deg);
      &::after {
        transform: rotate(30deg);
      }
    }
  }

  &.clock-12 {
    .custom-marker-body {
      right: 0;
      left: auto;
      top: -160px;
      transform: translateX(50%);
    }
    .custom-marker-decoration {
      transform: translate(50%, 0) rotate(0deg);
      &::after {
        transform: rotate(0deg);
      }
    }
  }
}

html[dir='rtl'] {
  body {
    text-align: right;
  }
}

html[dir='ltr'] {
  body {
    text-align: left;
  }

  .ml-3 {
    margin-left: 0!important;
    margin-right: 1rem!important;
  }

  .ml-2 {
    margin-left: 0!important;
    margin-right: .5rem!important;
  }

  .map-component {
    .additional-tools {
      left: auto;
      right: 1rem;
    }

    .controllers {
      transform: translateX(-100%);
      .toggle-back {
        right: auto;
        left: 100%;
        margin-right: 0;
        margin-left: 1rem;
      }

      .actions {
        .action {
          transform: scaleX(-1);
        }
      }
    }

    &.controllers-is-active {
      .controllers {
        transform: translateX(0%);
      }
    }
  }
}

